import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Jumbotron, Spinner, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from "uuid";
import firebase from 'firebase'
import { database } from "../config";
import MyListings from '../Components/MyListings';
import { Redirect } from "react-router-dom";
import { AiOutlinePoweroff } from 'react-icons/ai'


export default function MyProfile() {

    //Authstate
    const [authState, setAuthState] = useState("");
    const [userUid, setUserUid] = useState(null);
    const [profileCheck, setProfileCheck] = useState(null)
    //snapshots
    const [profileData, setProfileData] = useState([])
    //spinner
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        firebase.auth().onAuthStateChanged(function (user) {
            if (!user) {
                setAuthState("Logged-out")
            } else {
                setAuthState("Logged-in")
                setUserUid(user.uid)
                database.ref("My-Profile").orderByChild("userUid").equalTo(user.uid).on('value', (snapshot) => {
                    const items = [];
                    snapshot.forEach((childSnapshot) => {
                        var childData = childSnapshot.val();
                        items.push(childData);
                    });
                    setProfileData(items)
                });
                database.ref("My-Profile").orderByChild("userUid").equalTo(user.uid).once("value", (snapshot) => {
                    if (snapshot.exists()) {
                        setProfileCheck(true)
                        setLoading(false)
                    } else {
                        setProfileCheck(false)
                        setLoading(false)
                    }
                }).catch((error) => {
                    console.error(error);
                });
            }
        });
    }, [])



    if (authState === "Logged-out") {
        return (
            <>
                <Redirect to="/" />
            </>
        )
    }
    function logout() {
        firebase.auth().signOut()
        return (
            <>
                <Redirect to="/" />
            </>
        )
    }
    return (
        <div style={{ height: 'calc(100vh - 59px)', overflow: 'scroll', paddingBottom: '60px', backgroundColor: '#151515' }}>

            {profileCheck === false ? <div className="text-center"><Link to="/create-profile"><Button variant="primary mr-top">Create Profile Now</Button></Link></div> : ""}

            {/* SPINNER */}
            {loading === true ? (<div className="spinner-border spinner text-primary"></div>) : ""}

            {profileData.map((data) => (
                <Jumbotron key={uuidv4()} style={{ backgroundColor: '#404040', borderRadius: '0px', color: '#fff', padding: '1em', borderBottom: '1px solid #ccc' }}>
                    <Row>
                        <div onClick={() => { logout() }} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '20px', height: '40px', width: '40px', backgroundColor: '#ffffff40', padding: '0px 5px', position: 'absolute', right: 'calc(1em + 15px)', top: 'calc(1em + 15px)' }}>
                            <AiOutlinePoweroff style={{ fontSize: '30px', color: '#000' }} />
                        </div>
                        <div style={{ width: '100%', padding: '15px', paddingTop: '0', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img src={data.thumbnail} style={{ height: '80px', width: '80px', borderRadius: '40px' }} />
                            <div style={{ textTransform: 'capitalize', marginLeft: '20px' }}>
                                <h5>{data.name}</h5>
                                <h6>{data.email}</h6>
                            </div>
                        </div>
                        <Col sm={12} lg={10} md={10}>
                            <p style={{ marginBottom: '5px' }}><FontAwesomeIcon icon={faUser} /> {data.about}</p>
                            <p style={{ marginBottom: '5px' }}><FontAwesomeIcon icon={faMapMarkerAlt} /> {data.address}</p>
                        </Col>
                    </Row>
                </Jumbotron>
            ))}
            <MyListings />
        </div>
    )
}
