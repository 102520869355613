import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { database } from "../config";
import FeaturedCard from "../Components/FeaturedCard";

function LikedSaved() {
    const [likedOrSaved, setLikedOrSaved] = useState(true)
    const [liked, setLiked] = useState([])
    const [saved, setSaved] = useState([])
    useEffect(() => {
        firebase.auth().onAuthStateChanged(function (user) {
            if (!user) {
                console.log('not logged in');
            } else {
                database
                    .ref("User_likes")
                    .child(user.uid)
                    .once("value", (snapshot) => {
                        if (snapshot.exists()) {
                            let temp = []
                            snapshot.forEach((child) => {
                                temp.push(child.val().product)
                            })
                            setLiked(temp)
                        } else {
                            console.log('No Liked Products');
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                database
                    .ref("User_saved")
                    .child(user.uid)
                    .once("value", (snapshot) => {
                        if (snapshot.exists()) {
                            let temp = []
                            snapshot.forEach((child) => {
                                temp.push(child.val().product)
                            })
                            setSaved(temp)
                        } else {
                            console.log('No saved Products');
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    })
            }
        })
    }, [])

    return (
        <div className='home_container' style={{ backgroundColor: '#151515', paddingTop: '15px' }}>
            <div className='Liked_saved_switch_container'>
                <p onClick={() => { setLikedOrSaved(true) }} style={{ padding: '5px 20px', margin: '0', fontWeight: '1000', color: likedOrSaved ? '#000' : '#ff7400', background: !likedOrSaved ? '#151515' : '#ff7400', borderRadius: '8px' }}>Liked</p>
                <p onClick={() => { setLikedOrSaved(false) }} style={{ padding: '5px 20px', margin: '0', fontWeight: '1000', color: !likedOrSaved ? '#000' : '#ff7400', background: likedOrSaved ? '#151515' : '#ff7400', borderRadius: '8px' }}>Saved</p>
            </div>
            {likedOrSaved && liked.length !== 0 ? liked.map((data, id) => {
                return (
                    <>
                        <FeaturedCard data={data} />
                    </>
                );
            })
                :
                likedOrSaved && liked.length === 0 && <center><p style={{ color: '#fff' }}>No Liked Products Found</p></center>
            }
            {!likedOrSaved && saved.length !== 0 ? saved.map((data, id) => {
                return (
                    <>
                        <FeaturedCard data={data} />
                    </>
                );
            })
                :
                !likedOrSaved && saved.length === 0 && <center><p style={{ color: '#fff' }}>No Saved Products Found</p></center>
            }

        </div>
    )
}

export default LikedSaved