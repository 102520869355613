import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
} from "react-bootstrap";
import { MdVerifiedUser } from 'react-icons/md';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

import { HiHeart, HiOutlineBookmark, HiOutlineChat, HiOutlineChatAlt, HiOutlineChatAlt2 } from 'react-icons/hi'
import firebase from "firebase";
import { database } from "../config";

export default function MyListings() {
  const [show, setShow] = useState(false);
  const [deleteKey, setDeleteKey] = useState("")

  const handleClose = () => setShow(false);
  const handleShow = (key) => {
    setDeleteKey(key)
    setShow(true);
  }

  //Authstate
  const [authState, setAuthState] = useState(null);
  const [userUid, setUserUid] = useState(null);
  const [listingsCheck, setListingsCheck] = useState(null);
  //snapshots
  const [listings, setListings] = useState([]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        setAuthState(false);
      } else {
        setAuthState(true);
        setUserUid(user.uid);
        database
          .ref("products")
          .orderByChild("userUid")
          .equalTo(user.uid)
          .once("value", (snapshot) => {
            if (snapshot.exists()) {
              setListingsCheck(true);

            } else {
              setListingsCheck(false);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  }, []);


  //get listing data
  useEffect(() => {
    database
      .ref("products")
      .orderByChild("userUid")
      .equalTo(userUid)
      .on("value", (snapshot) => {
        const items = [];
        snapshot.forEach((childSnapshot) => {
          var childKey = childSnapshot.key;
          var data = childSnapshot.val();
          items.push({
            key: childKey,
            ...data
          });
        });
        setListings(items);
      });
  }, [userUid]);
  //

  return (
    <div>
      {listingsCheck === true ? <p style={{ color: 'black', fontSize: '1.2em', margin: '0px', color: '#ccc', marginLeft: '20px', marginBottom: '20px' }}>My Products</p> : ""}
      <div className="featured_card_grid_container" style={{ padding: '0px 20px' }}>
        {listings.map((data, id) => (
          <div className="featured_card_container" style={{ height: '100px', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
              <Link style={{ width: '140px' }} to={{ pathname: '/product', search: `?${data.key}`, state: { fromDashboard: true } }}>
                <img
                  src={data.imageOneURL}
                  style={{ borderRadius: '0px', height: '100px', width: '140px' }}
                />
              </Link>
              <div style={{ width: '100%', height: '100px', color: '#fff', padding: '10px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                      <span >{data.title}</span>
                    </div>
                    <div style={{ flex: '1', minWidth: '10px' }}></div>
                    <span style={{ fontSize: '1.3em', color: '#fff', borderRadius: '8px', padding: '2px 2px' }}>${Number(data.sellingPrice).toLocaleString('en')}</span>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button variant="danger" onClick={(e) => { handleShow(data.key) }}  >
                      Delete
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>DevBud</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                const propertyDB = firebase
                  .database()
                  .ref("products")
                  .child(deleteKey);
                propertyDB.remove();
                handleClose();
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
} 