import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { database } from "../config";
import FeaturedCard from "../Components/FeaturedCard";
import { useLocation } from "react-router-dom";
import TopBar from "../Components/TopBar";

export default function SpecificProducts() {
    //Authstate
    const [authState, setAuthState] = useState(null);
    const [userUid, setUserUid] = useState(null);
    const [listingsCheck, setListingsCheck] = useState(null);
    //snapshots
    const [listings, setListings] = useState([]);
    //spinner
    const [loading, setLoading] = useState(true)
    const location = useLocation();
    const [category, setCategory] = useState("")
    const [subCategory, setSubCategory] = useState("")
    useEffect(() => {
        firebase.auth().onAuthStateChanged(function (user) {
            if (!user) {
                setAuthState(false);
            } else {
                setAuthState(true);
                setUserUid(user.uid);
            }
        });
    }, []);


    useEffect(() => {
        if (location.state?.category || location.state?.subCategory) {
            location.state?.category && setCategory(location.state?.category)
            location.state?.subCategory && setSubCategory(location.state?.subCategory)
        }
        database
            .ref("products")
            .once("value", (snapshot) => {
                if (snapshot.exists()) {
                    setListingsCheck(true);
                    { setLoading(false) }
                    if (category !== "") {
                        console.log(category);
                        database
                            .ref("products")
                            .orderByChild("category")
                            .equalTo(category)
                            .on("value", (snapshot) => {
                                const items = [];
                                snapshot.forEach((childSnapshot) => {
                                    var childKey = childSnapshot.key;
                                    var data = childSnapshot.val();
                                    items.push({ key: childKey, ...data });
                                });
                                setListings(items);
                            });
                    } else if (subCategory !== "") {
                        console.log(subCategory);
                        database
                            .ref("products")
                            .orderByChild("subCategory")
                            .equalTo(subCategory)
                            .on("value", (snapshot) => {
                                const items = [];
                                snapshot.forEach((childSnapshot) => {
                                    var childKey = childSnapshot.key;
                                    var data = childSnapshot.val();
                                    items.push({ key: childKey, ...data });
                                });
                                setListings(items);
                            });
                    }
                } else {
                    setListingsCheck(false);
                    { setLoading(false) }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [userUid]);
    return (
        <>
            <TopBar title={`${category} ${subCategory}`} />
            <div className="featured-section" style={{ background: '#151515', padding: '80px 0px', minHeight: '100vh' }}>
                {loading === true ? <div className="sk-cube-grid">
                    <div className="sk-cube sk-cube1"></div>
                    <div className="sk-cube sk-cube2"></div>
                    <div className="sk-cube sk-cube3"></div>
                    <div className="sk-cube sk-cube4"></div>
                    <div className="sk-cube sk-cube5"></div>
                    <div className="sk-cube sk-cube6"></div>
                    <div className="sk-cube sk-cube7"></div>
                    <div className="sk-cube sk-cube8"></div>
                    <div className="sk-cube sk-cube9"></div>
                </div> : ""}
                <div className="featured_card_grid_container" >
                    {listings.length !== 0 && listings.map((data, id) => {
                        return (
                            <>
                                <FeaturedCard data={data} />
                            </>
                        );
                    })}
                    {
                        !loading && listings.length === 0 && <center><p style={{ color: '#fff' }}>No products found</p></center>
                    }
                </div>
            </div >
        </>
    );
}
