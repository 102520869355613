import React, { useState, useEffect } from "react";
import {
    Row,
} from "react-bootstrap";
import firebase from "firebase";
import { database } from "../config";
import TopBar from "../Components/TopBar";
import FeaturedCard from "../Components/FeaturedCard";

export default function Search() {
    //Authstate
    const [authState, setAuthState] = useState(null);
    const [userUid, setUserUid] = useState(null);
    const [listingsCheck, setListingsCheck] = useState(null);
    //snapshots
    const [listings, setListings] = useState([]);
    //spinner
    const [loading, setLoading] = useState(true)
    //name of category
    const [categoryName, setCategoryName] = useState('')
    useEffect(() => {
        firebase.auth().onAuthStateChanged(function (user) {
            if (!user) {
                setAuthState(false);
            } else {
                setAuthState(true);
                setUserUid(user.uid);
            }
        });
    }, []);


    useEffect(() => {
        database
            .ref("products")
            .once("value", (snapshot) => {
                if (snapshot.exists()) {
                    setListingsCheck(true);
                    { setLoading(false) }
                } else {
                    setListingsCheck(false);
                    { setLoading(false) }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [userUid]);
    //

    //get listing data
    useEffect(() => {
        const queryString = window.location.search
        const RetrivedPageName = queryString.substring(1).replace(/%20/g, " ")
        let filter = "company"
        const types = ['Sedan', 'Coupe', 'Hatchback', 'Wagon', 'Van/Minivan', 'Pickup', 'Convertible', 'SUV']
        setCategoryName(RetrivedPageName)
        if (types.includes(RetrivedPageName)) {
            filter = "category"
        }
        database
            .ref("products")
            .orderByChild(filter)
            .equalTo(RetrivedPageName)
            .on("value", (snapshot) => {
                const items = [];
                snapshot.forEach((childSnapshot) => {
                    var childKey = childSnapshot.key;
                    var data = childSnapshot.val();
                    items.push({
                        key: childKey,
                        name: data.name,
                        email: data.email,
                        phoneNumber: data.phoneNumber,
                        category: data.category,
                        company: data.company,
                        modelName: data.modelName,
                        address: data.address,
                        title: data.title,
                        about: data.about,
                        sellingPrice: data.sellingPrice,
                        miles: data.miles,
                        seats: data.seats,
                        transmission: data.transmission,
                        fuel: data.fuel,
                        noOfOwners: data.noOfOwners,
                        ac: data.ac,
                        airBags: data.airBags,
                        leatherseats: data.leatherseats,
                        sunroof: data.sunroof,
                        heatedSeats: data.heatedSeats,
                        backupCamera: data.backupCamera,
                        navigationSystem: data.navigationSystem,
                        bluetooth: data.bluetooth,
                        abs: data.abs,
                        userUid: data.userUid,
                        videoOneURL: data.videoOneURL,
                        imageOneURL: data.imageOneURL,
                        imageTwoURL: data.imageTwoURL,
                        imageThreeURL: data.imageThreeURL,
                        imageFourURL: data.imageFourURL,
                        lngLat: data.lngLat,
                    });
                });
                setListings(items);
            });
    }, [userUid]);
    //

    return (
        <>
            <TopBar title={categoryName} />
            {/* Spinner */}
            {loading === true ? <div className="sk-cube-grid">
                <div className="sk-cube sk-cube1"></div>
                <div className="sk-cube sk-cube2"></div>
                <div className="sk-cube sk-cube3"></div>
                <div className="sk-cube sk-cube4"></div>
                <div className="sk-cube sk-cube5"></div>
                <div className="sk-cube sk-cube6"></div>
                <div className="sk-cube sk-cube7"></div>
                <div className="sk-cube sk-cube8"></div>
                <div className="sk-cube sk-cube9"></div>
            </div> : ""}


            <div className="window_container" style={{ paddingTop: '80px' }}>
                <Row>
                    {listings.map((data, id) => (
                        <FeaturedCard data={data} />
                    ))}
                </Row>
            </div>
            <br />
            <br />
        </>
    );
}
