import React from 'react'
import TopBar from '../Components/TopBar'

function SearchUser() {
    return (
        <div>
            <TopBar title="Search" />
        </div>
    )
}

export default SearchUser