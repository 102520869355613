import { useHistory } from "react-router-dom";
import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
function TopBar(props) {
    const history = useHistory();
    return (
        <div className='topbar_container' >
            <div onClick={history.goBack} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <FontAwesomeIcon icon={faAngleLeft} style={{ padding: '0px 27px', fontSize: '1.6em', color: '#fff' }} />
            </div>
            <h2 style={{ color: '#fff', fontSize: '1.2em', fontWeight: '500', margin: '0', letterSpacing: '0px' }}>
                {props.title}
            </h2>
        </div>
    )
}

export default TopBar