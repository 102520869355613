import firebase from 'firebase'
import "firebase/storage" 

var firebaseConfig = {
  apiKey: "AIzaSyDfVCowTr9-0zpbKg5bhqjsMD5PAJNpKlo",
  authDomain: "fashiongoat-5099c.firebaseapp.com",
  projectId: "fashiongoat-5099c",
  storageBucket: "fashiongoat-5099c.appspot.com",
  messagingSenderId: "570554360081",
  appId: "1:570554360081:web:7060a8bd1550873cf26cb0",
  measurementId: "G-ZWNFDQV8HV"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export var database = firebase.database();
export const auth = firebase.auth(); 
export const storage = firebase.storage(); 
