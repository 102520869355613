import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import Video from '../Components/Video';
import { database } from "../config";
import SingleProperty from '../pages/SinglePropertyPage'
import {
  faAngleLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Redirect } from "react-router-dom";
import { HiInformationCircle, HiX } from 'react-icons/hi';
import { Modal, Toast } from 'react-bootstrap'; 

function Home() {
  const [authState, setAuthState] = useState("");
  const [userUid, setUserUid] = useState(null);
  const [isPopupShown, setIsPopupShown] = useState(false)
  const [listingsCheck, setListingsCheck] = useState(null);
  const [key, setKey] = useState('')
  const [title, setTitle] = useState('')
  //snapshots
  const [listings, setListings] = useState([]);
  //spinner
  const [loading, setLoading] = useState(true)
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (key) => {
    setShow(true);
  }




   

  useEffect(() => { 
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        setAuthState("Logged-out")
      } else {
        setAuthState("Logged-in")
        setUserUid(user.uid);
      }
    });
  }, []);


  useEffect(() => {
    database
      .ref("products")
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          setListingsCheck(true);
          { setLoading(false) }
        } else {
          setListingsCheck(false);
          { setLoading(false) }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userUid]);
  //

  //get listing data
  useEffect(() => {
    if (listings.length === 0) {
      database
        .ref("products")
        .limitToLast(10)
        .on("value", (snapshot) => {
          const items = [];
          snapshot.forEach((childSnapshot) => {
            var childKey = childSnapshot.key;
            var data = childSnapshot.val();
            console.log();
            items.push({ key: childKey, ...data });
          });
          setListings(items.reverse());
        });
    }
  }, [userUid]);
  const [muted, setMuted] = useState(true)

  if (authState === "Logged-out") {
    return (
      <>
        <Redirect to="/" />
      </>
    )
  }
  return (
    <div className='home_container'>
      {loading === true ? <div className="sk-cube-grid">
        <div className="sk-cube sk-cube1"></div>
        <div className="sk-cube sk-cube2"></div>
        <div className="sk-cube sk-cube3"></div>
        <div className="sk-cube sk-cube4"></div>
        <div className="sk-cube sk-cube5"></div>
        <div className="sk-cube sk-cube6"></div>
        <div className="sk-cube sk-cube7"></div>
        <div className="sk-cube sk-cube8"></div>
        <div className="sk-cube sk-cube9"></div>
      </div> : ""}
      <div style={{ position: 'fixed', top: '0', left: '0', zIndex: '999', padding: '15px', display: `${isPopupShown ? 'none' : 'flex'}`, justifyContent: 'space-between', width: '100%' }}>
        <span>
          <h4 style={{ margin: '0', color: '#fff', fontWeight: '500', textAlign: 'left' }}>Gallerina</h4>
          <h6 style={{ margin: '0', color: '#fff', fontWeight: '500', textAlign: 'left' }}>Products Go Social</h6>
        </span>
        <HiInformationCircle style={{ fontSize: '2em', color: '#fff' }} onClick={() => handleShow()} />
      </div>
      <Modal show={show} onHide={handleClose}>
        <div style={{ padding: '10px 10px' }}>
          <h4 style={{ margin: '0', color: '#fff', fontWeight: '500', textAlign: 'left' }}>Gallerina</h4>
          <h6 style={{ margin: '0', color: '#fff', fontWeight: '500', textAlign: 'left', marginBottom: '20px' }}>Products Go Social</h6>
          <h6 style={{ margin: '0', color: '#fff', fontWeight: '500', textAlign: 'left' }}>Contact us: <span style={{ color: '#ff7400' }}>gallerina02@gmail.com</span></h6>
        </div>
        <div onClick={() => { handleClose() }} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', right: '10px', top: '10px', height: '50px', width: '50px', borderRadius: '25px', color: '#fff', backgroundColor: '#ffffff30' }}>
          <HiX style={{ fontSize: '2em' }} />
        </div>
      </Modal>
       
      <div style={{ display: `${isPopupShown ? 'none' : 'block'}` }}>
        {listings.length !== 0 && (listings.map((data, i) => {
          if (data.videoURL !== "") {
            return (
              <>
                <Video
                  key={i}
                  pos={i}
                  data={data}
                  length={listings.length}
                  muted={muted}
                  setMuted={setMuted}
                  setIsPopupShown={setIsPopupShown}
                  setKey={setKey}
                  setTitle={setTitle} />
              </>
            )
          }
        }))}
      </div>
      {
        isPopupShown ?
          <>
            <div style={{ height: 'max-content', width: '100vw', backgroundColor: '#fff' }}>
              <div className='topbar_container'>
                <div onClick={() => { setIsPopupShown(false) }}>
                  <FontAwesomeIcon icon={faAngleLeft} style={{ padding: '0px 27px', fontSize: '1.6em', color: '#fff' }} />
                </div>
                <h2 style={{ color: '#fff', fontSize: '1.1em', fontWeight: '500', margin: '0' }}>
                  {title}
                </h2>
              </div>
              <SingleProperty pagekey={key} title={title} setIsPopupShown={setIsPopupShown} />
            </div>
          </>
          : <></>
      }
    </div>
  )
}
export default React.memo(Home)