import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { MdLocalFireDepartment, MdPerson } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { Redirect } from "react-router-dom";
import { database } from "../config";
import firebase from 'firebase'
import { HiHeart } from 'react-icons/hi'
import { TiHome } from 'react-icons/ti'
import { IoIosAddCircleOutline } from 'react-icons/io'


function BottomNav() {
    const location = useLocation();
    const [authState, setAuthState] = useState(null);
    const [profileData, setProfileData] = useState([])

    useEffect(() => {
        firebase.auth().onAuthStateChanged(function (user) {
            if (!user) {
                setAuthState("Logged-out")
            } else {
                setAuthState("Logged-in")
                database.ref("My-Profile").orderByChild("userUid").equalTo(user.uid).on('value', (snapshot) => {
                    const items = [];
                    snapshot.forEach((childSnapshot) => {
                        var childData = childSnapshot.val();
                        items.push(childData);
                    });
                    setProfileData(items)
                });
            }
        });
    }, []);
    if (authState === "Logged-out") {
        return (
            <>
                <Redirect to="/" />
            </>
        )
    } else {
        return (
            <div className='bottom_nav_container'>
                <Link to="/home" style={{ flex: '1', fontSize: '12px', display: 'flex', flexDirection: 'column', color: `${location.pathname === '/home' ? '#ff7400' : '#404040'}`, justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }}>
                    <TiHome style={{
                        color: `${location.pathname === '/home' || location.pathname === '/home/product' ? '#ff7400' : '#404040'}`,
                        fontSize: '28px',
                    }} />
                    Home
                </Link>
                <Link to="/explore" style={{ flex: '1', fontSize: '12px', display: 'flex', flexDirection: 'column', color: `${location.pathname === '/explore' || location.pathname === '/search-user' || location.pathname === '/specific-products' || String(location.pathname).slice(0, 9) === '/product' ? '#ff7400' : '#404040'}`, justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }}>
                    <MdLocalFireDepartment style={{
                        color: `${location.pathname === '/explore' || location.pathname === '/specific-products' || location.pathname === '/search-user' || String(location.pathname).slice(0, 9) === '/product' ? '#ff7400' : '#404040'}`,
                        fontSize: '28px',
                    }} />
                    Trending
                </Link>
                <Link to="/become-host" style={{ flex: '1', fontSize: '12px', display: 'flex', flexDirection: 'column', color: `${location.pathname === '/become-host' ? '#ff7400' : '#404040'}`, justifyContent: 'center', alignItems: 'center' }}>
                    <IoIosAddCircleOutline style={{
                        color: `${location.pathname === '/become-host' ? '#ff7400' : '#404040'}`,
                        fontSize: '50px',
                    }} />
                </Link>
                <Link to="/liked-saved" style={{ flex: '1', fontSize: '12px', display: 'flex', flexDirection: 'column', color: `${location.pathname === '/liked-saved' ? '#ff7400' : '#404040'}`, justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }}>
                    <HiHeart style={{
                        color: `${location.pathname === '/liked-saved' ? '#ff7400' : '#404040'}`,
                        fontSize: '28px',
                    }} />
                    Liked
                </Link>
                <Link to="/my-profile" style={{ flex: '1', fontSize: '12px', display: 'flex', flexDirection: 'column', color: `${location.pathname === '/my-profile' || location.pathname === '/create-profile' ? '#ff7400' : '#404040'}`, justifyContent: 'center', alignItems: 'center', paddingTop: '2px' }}>
                    {profileData.length !== 0 ? <img src={profileData[0].thumbnail}
                        style={{
                            border: `2px solid ${location.pathname === '/my-profile' || location.pathname === '/create-profile' ? '#ff7400' : '#000'}`,
                            padding: `${location.pathname === '/my-profile' || location.pathname === '/create-profile' ? '1px' : '1px'}`,
                            height: '30px', width: '30px', borderRadius: '15px'
                        }} />
                        :
                        <MdPerson style={{
                            color: `${location.pathname === '/my-profile' || location.pathname === '/create-profile' ? '#ff7400' : '#404040'}`,
                            fontSize: '28px',
                        }} />
                    }
                    Profile
                </Link>
            </div>
        )
    }
}

export default BottomNav