import React, { useState, useEffect } from "react";
import { Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { database } from "../config";

export default function CategoriesSection() {
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  useEffect(() => {
    database.ref("categories").once("value", (snapshot) => {
      if (snapshot.exists()) {
        const items = [];
        snapshot.forEach((childSnapshot) => {
          var childData = childSnapshot.val();
          var childKey = childSnapshot.key;
          items.push({ ...childData, key: childKey });
        });
        setCategories(items)
      }
    }).catch((error) => {
      console.error(error);
    });
    database.ref("sub_categories").once("value", (snapshot) => {
      if (snapshot.exists()) {
        const items = [];
        snapshot.forEach((childSnapshot) => {
          var childData = childSnapshot.val();
          var childKey = childSnapshot.key;
          items.push({ ...childData, key: childKey });
        });
        setSubCategories(items)
      }
    }).catch((error) => {
      console.error(error);
    });
  }, [])

  return (
    <div style={{ padding: '10px 0px' }}>
      <p style={{ color: 'black', fontSize: '1.2em', margin: '0px', color: '#ccc', marginLeft: '20px' }}>Browse By Type</p>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        overflow: 'scroll',
        padding: '10px 20px',
        paddingRight: '0px'
      }}>
        {
          subCategories.length !== 0 && subCategories.map((type) => {
            return (
              <Col sm={12} md={4} lg={4} style={{ maxWidth: 'min-content', padding: '0', height: '100px', width: '100px', marginRight: '10px', borderRadius: '30px' }} >
                <Link to={{ pathname: `${type.linkTo}`, state: { fromDashboard: true, subCategory: type.name } }}>
                  <Card className="category-cards" style={{ display: 'flex', alignItems: 'center', height: '100px', width: '100px', padding: '10px', border: 'none', borderRadius: '30px', boxShadow: '0px 0px 10px #000', background: 'radial-gradient(circle at 30% 107%, #447400 0%, red 5%, #fd5949 45%,#ff7400 60%,#ff7400 90%)' }} >
                    <Card.Img variant="top" src={type.url} className="category-img" style={{ height: '60px', width: '60px' }} />
                    <Card.Body style={{ padding: '5px', position: 'absolute', bottom: '0', left: '0', width: '100%', borderRadius: '5px' }}>
                      <p style={{ fontWeight: 'normal', margin: '0', color: '#000', textAlign: 'center' }}>{type.name}</p>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            )
          })
        }
      </div>
      <p style={{ color: 'black', fontSize: '1.2em', margin: '0px', color: '#ccc', marginLeft: '20px' }}>Browse By Category</p>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        overflow: 'scroll',
        padding: '10px 20px',
        paddingRight: '0px'
      }}>
        {
          categories.length !== 0 && categories.map((type) => {
            return (
              <Col sm={12} md={4} lg={4} style={{ maxWidth: 'min-content', padding: '0', height: '200px', width: '140px', marginRight: '12px', borderRadius: '20px' }} >
                <Link to={{ pathname: `${type.linkTo}`, state: { fromDashboard: true, category: type.name } }}>
                  <Card className="category-cards" style={{ height: '200px', width: '140px', borderRadius: '20px' }}>
                    <Card.Img variant="top" src={type.url} className="category-img" style={{ height: '100%', width: '100%', borderRadius: '20px' }} />
                    <Card.Body style={{ padding: '5px', paddingLeft: '10px', position: 'absolute', bottom: '0', width: '100%', borderRadius: '20px', backgroundImage: 'linear-gradient(#00000000, #00000060)' }}>
                      <p style={{ fontWeight: 'normal', margin: '0', color: '#fff' }}>{type.name}</p>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            )
          })
        }
      </div>
    </div>
  );
}
