import React, { useEffect, useState } from 'react';
import CategoriesSection from '../Components/CategoriesSection';
import { Redirect } from "react-router-dom";
import firebase from "firebase";
import { database } from "../config";
import { HiChevronRight, HiOutlineSearch } from 'react-icons/hi';
import FeaturedSection from '../Components/featuredSection';
import { Link } from "react-router-dom";


function Explore() {
    const [authState, setAuthState] = useState("");
    const [lngLat, setLngLat] = useState(null)

    useEffect(() => {
        firebase.auth().onAuthStateChanged(function (user) {
            if (!user) {
                setAuthState("Logged-out")
            } else {
                setAuthState("Logged-in")
                database.ref("My-Profile").orderByChild("userUid").equalTo(user.uid).on('value', (snapshot) => {
                    let location = [];
                    snapshot.forEach((child) => {
                        location.push(child.val().lngLat)
                    })
                    setLngLat(location[0])
                });
            }
        });
    }, []);
    if (authState === "Logged-out") {
        return (
            <>
                <Redirect to="/" />
            </>
        )
    } else {
        return (
            <div className='explore_container'>
                <div style={{
                    width: '100%', maxWidth: '600px', height: '210px', position: 'relative', background: '#000', backgroundImage: "url('https://images.unsplash.com/photo-1593129747951-db31f82963da?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzM4fHxmYXNoaW9ufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60')"
                    , backgroundSize: 'cover'
                }}>
                    <div style={{ position: 'absolute', top: '20px', left: '20px', zIndex: '999', display: 'flex', justifyContent: 'space-between', width: 'max-content' }}>
                        <span>
                            <h6 style={{ margin: '0', marginBottom: '5px', color: '#fff', fontSize: '1.6em', webkitTextStrokeWidth: 'thin', textAlign: 'left' }}>New Arrivals</h6>
                            <h6 style={{ margin: '0', marginBottom: '10px', color: '#ccc', fontWeight: '500', textAlign: 'left' }}>check out fresh new stuff<br />from Gallerina</h6>
                            <div style={{ color: '#000', padding: '5px 10px', background: 'radial-gradient(circle at 30% 107%, #447400 0%, red 5%, #fd5949 45%,#ff7400 60%,#ff7400 90%)', display: 'flex', alignItems: 'center', borderRadius: '10px', width: 'min-content', borderRadius: '10px' }}>
                                Explore <HiChevronRight style={{ fontSize: '1.2em', marginLeft: '5px' }} />
                            </div>
                        </span>
                    </div>
                    {/* <div style={{ position: 'absolute', top: '20px', right: '20px', zIndex: '999', display: 'flex', justifyContent: 'space-between', width: 'max-content' }}>
                        <span>
                            <Link to={'/search-user'}>
                                <div style={{ color: '#000', padding: '10px', background: 'radial-gradient(circle at 30% 107%, #447400 0%, red 5%, #fd5949 45%,#ff7400 60%,#ff7400 90%)', display: 'flex', alignItems: 'center', borderRadius: '10px', width: 'min-content', borderRadius: '50%' }}>
                                    <HiOutlineSearch style={{ fontSize: '2em' }} />
                                </div>
                            </Link>
                        </span>
                    </div> */}
                </div>
                <CategoriesSection />
                <FeaturedSection />
            </div>
        )
    }

}

export default Explore