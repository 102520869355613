import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { database } from "../config";
import FeaturedCard from "./FeaturedCard";

export default function FeaturedSection() {
  //Authstate
  const [authState, setAuthState] = useState(null);
  const [userUid, setUserUid] = useState(null);
  const [listingsCheck, setListingsCheck] = useState(null);
  //snapshots
  const [listings, setListings] = useState([]);
  //spinner
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        setAuthState(false);
      } else {
        setAuthState(true);
        setUserUid(user.uid);
      }
    });
  }, []);


  useEffect(() => {
    database
      .ref("products")
      .once("value", (snapshot) => {
        if (snapshot.exists()) {
          setListingsCheck(true);
          { setLoading(false) }
        } else {
          setListingsCheck(false);
          { setLoading(false) }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userUid]);
  //

  //get listing data
  useEffect(() => {
    database
      .ref("products")
      .limitToLast(5)
      .on("value", (snapshot) => {
        const items = [];
        snapshot.forEach((childSnapshot) => {
          var childKey = childSnapshot.key;
          var data = childSnapshot.val();
          items.push({ key: childKey, ...data });
        });
        setListings(items);
      });
  }, [userUid]);
  return (
    <>

      {/* Spinner */}
      {loading === true ? <div className="sk-cube-grid">
        <div className="sk-cube sk-cube1"></div>
        <div className="sk-cube sk-cube2"></div>
        <div className="sk-cube sk-cube3"></div>
        <div className="sk-cube sk-cube4"></div>
        <div className="sk-cube sk-cube5"></div>
        <div className="sk-cube sk-cube6"></div>
        <div className="sk-cube sk-cube7"></div>
        <div className="sk-cube sk-cube8"></div>
        <div className="sk-cube sk-cube9"></div>
      </div> : ""}

      <div className="featured-section">
        {listingsCheck === true ? <p style={{ color: 'black', fontSize: '1.2em', margin: '0px', color: '#ccc', marginLeft: '20px', marginBottom: '10px' }}>Featured</p> : ""}

        <div className="featured_card_grid_container" >
          {listings.map((data, id) => {
            return (
              <>
                <FeaturedCard data={data} />
              </>
            );
          })}
        </div>
      </div >
    </>
  );
}
